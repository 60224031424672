import React from 'react';
import { Helmet } from 'react-helmet';

const ServicesOnHocal = () => {
  // Sample data for services with image URLs and text
  const servicesData = [
    { imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTRcY9z-JZO0CfPt6QsApdbBBe8bvsuc-OhGA&s', text: 'Car Service' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Medicine Delivery' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Mechanic' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Plumber' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Grocery Delivery' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Electrician' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Home Repair' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Trip Guide' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Virtual Guide' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Trip Planner' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Auto Booking' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Cab Booking' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Bike Booking' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Fast Delivery' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Home Cleaning' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Electronics Repair' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Emergency Service' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Appliance Service' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Bike Booking' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Fast Delivery' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Home Cleaning' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Electronics Repair' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Emergency Service' },
    { imageUrl: 'https://via.placeholder.com/300x200', text: 'Appliance Service' },
  ];

  return (
    <div className="md:mt-20 md:ml-[100px] md:p-8 p-5">
      <Helmet>
        <title>Services Available on Hocal | Hocal</title>
        <meta name="description" content="Discover the wide range of services you can take or give on Hocal. From car service to home cleaning, Hocal connects you with local experts for all your needs." />
        <meta name="keywords" content="Hocal services, car service, medicine delivery, mechanic, plumber, grocery delivery, electrician, home repair, trip guide, virtual guide, trip planner, auto booking, cab booking, bike booking, fast delivery, home cleaning, electronics repair, emergency service, appliance service" />
        <meta name="author" content="Hocal Team" />
        <meta property="og:title" content="Services Available on Hocal | Hocal" />
        <meta property="og:description" content="Discover the wide range of services you can take or give on Hocal. From car service to home cleaning, Hocal connects you with local experts for all your needs." />
        <meta property="og:image" content="URL-to-your-og-image.jpg" />
        <meta property="og:url" content="https://hocal.in/services" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Services Available on Hocal | Hocal" />
        <meta name="twitter:description" content="Discover the wide range of services you can take or give on Hocal. From car service to home cleaning, Hocal connects you with local experts for all your needs." />
        <meta name="twitter:image" content="URL-to-your-twitter-image.jpg" />
        <link rel="canonical" href="https://yourwebsite.com/services" />
      </Helmet>
      <h2 className="text-3xl lg:text-4xl font-bold my-5">What <span className="text-white bg-yellow-500 px-[5px] rounded">Services</span> you can take or give on Hocal?</h2>
      <div className="grid grid-cols-3 lg:grid-cols-6 gap-4 md:py-10">
        {servicesData.map((service, index) => (
          <div key={index} className="relative group">
            <img src={service.imageUrl} alt={service.text} className="w-full h-auto rounded-lg" />
            <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
              <p className="text-white text-md font-semibold text-center">{service.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicesOnHocal;

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validateEmail(formData.email)) {
      alert('Please enter a valid email address.');
      return;
    }
    if (!validateMobile(formData.mobile)) {
      alert('Please enter a valid mobile number.');
      return;
    }
    // Submit the form data here
    console.log('Form submitted:', formData);
    // Clear the form after submission
    setFormData({
      name: '',
      email: '',
      mobile: '',
      message: ''
    });
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const validateMobile = (mobile) => {
    const re = /^\d{10}$/;
    return re.test(String(mobile));
  };

  return (
    <div>

<Helmet>
  <title>Contact Hocal | Get in Touch with Our Support Team</title>
  <meta name="description" content="Need assistance? Contact Hocal's support team for help with your service requests, app issues, or any questions you may have." />
  <meta name="keywords" content="Hocal, hyperlocal, Hocal contact, Hocal contact page, Hocal support, Hocal customer service, Hocal app contact, contact Hocal, service app support, local services support, Hocal help, get in touch with Hocal" />
</Helmet>

      <section className=" md:pl-[100px] flex flex-col md:flex-row items-center lg:h-screen bg-gradient-to-b from-yellow-100 to-yellow-0 relative">
        <div className="flex-shrink-0 w-full md:w-1/2 px-4 md:px-0 mb-4 md:mb-0">
          <img src="image/hocal-contact-us.svg" alt="hocal contact page, how to contact hocal, hocal support page, hocal help page," className="p-10 h-auto w-full object-contain transition-transform duration-500 hover:scale-105" loading="lazy" />
        </div>
        <div className="contact-form w-full md:w-1/2 px-4 md:px-8 mb-8 md:mb-0 md:grid md:grid-cols-2 md:gap-8 p-6 rounded-lg">
          <div className="md:col-span-1 h-full">
            <h1 className="text-3xl md:text-4xl font-bold text-black mb-4 pt-3">Contact Us</h1>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="mb-4">
                <label htmlFor="name" className="block text-sm font-medium text-black">Name</label>
                <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className="mt-1 block py-4 px-4 w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black bg-transparent transition-all duration-300 ease-in-out hover:bg-white hover:bg-opacity-50" required />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-sm font-medium text-black">Email</label>
                <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className="mt-1 py-4 px-4 block w-full border rounded-lg shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black bg-transparent transition-all duration-300 ease-in-out hover:bg-white hover:bg-opacity-50" required />
              </div>
              <div className="mb-4">
                <label htmlFor="mobile" className="block text-sm font-medium text-black">Mobile Number</label>
                <input type="tel" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} className="mt-1 py-4 px-4 block w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black bg-transparent transition-all duration-300 ease-in-out hover:bg-white hover:bg-opacity-50" required />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className="block text-sm font-medium text-black">Message</label>
                <textarea id="message" name="message" rows="4" value={formData.message} onChange={handleChange} className="px-4 py-4 mt-1 block w-full border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black bg-transparent transition-all duration-300 ease-in-out hover:bg-white hover:bg-opacity-50" required></textarea>
              </div>
              <button type="submit" className="bg-yellow-500 mt-5 text-black font-semibold text-lg py-2 px-9 rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 transition-transform duration-300 ease-in-out hover:scale-105">Submit</button>
            </form>
          </div>
        </div>
      </section>

    </div>
  );
};

export default ContactUs;

import React, { useEffect } from 'react';
import Swiper from 'swiper/bundle';
import 'swiper/swiper-bundle.css';
import './Swiper.css'; // Import your custom CSS for Swiper styles
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const ImageSlider = ({ images }) => {
  useEffect(() => {
    const swiper = new Swiper('.swiper-container', {
      loop: true,
      modules:{Autoplay, Pagination, Navigation},
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true, // Enable clickable pagination dots
        bulletClass: 'swiper-pagination-bullet',
        bulletActiveClass: 'swiper-pagination-bullet-active',
      },
    });

    return () => {
      if (swiper) {
    
      }
    };
  }, []);

  return (
    <div className="swiper-container w-full">
      <div className="swiper-wrapper w-full">
        {images.map((image, index) => (
          <div key={index} className="swiper-slide w-full">
            <img className='w-full' src={image} alt={image} />
          </div>
        ))}
      </div>
      <div className="swiper-pagination"></div> {/* Pagination dots */}
    </div>
  );
}

export default ImageSlider;

import React from 'react';

const BusinessWithHocal = () => {
  return (
    <div className="md:h-screen flex items-center my-5 p-5 justify-center md:px-[100px] bg-gradient-to-b from-yellow-50 to-yellow-0">
      <div className=" flex flex-col md:flex-row justify-between items-center w-full">
        <div className="text-section md:w-1/2 md:text-left mb-20 md:mb-0">
          <h1 className="md:text-6xl text-3xl font-bold">Grow your services & earn more, Hocal is <span className='text-white bg-yellow-500 rounded-md px-[10px]'>FREE for all.</span></h1>
      
          <p className="mt-4 text-base">
            Hocal is a platform where you can easily earn money by assisting nearby individuals with their outdoor or indoor tasks. It's like an open-source platform where you can work on your own terms and generate income effortlessly.
          </p>
        </div>
        <div className="image-section md:w-1/2">
          <img className="rounded-lg w-full h-1/2" src="image/grow-with-hocal.svg" alt="grow your business with hocal join hocal today and get larger audience around you and start earning" loading='lazy' />
        </div>
      </div>
    </div>
  );
}

export default BusinessWithHocal;

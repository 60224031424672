import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  const closeNavbar = () => {
    setIsOpen(false);
  };

  return (
    <>
      {/* Vertical navbar for large screens */}
      <nav className="hidden md:flex fixed left-0 top-0 h-full w-16 flex-col items-center shadow-md bg-grey z-10 bg-opacity-60 backdrop-filter backdrop-blur-xl">
        <div className="flex-shrink-0 py-4">
          <Link to="/" className="flex items-center justify-center h-16">
            <img src='image/hocal-logo.png' alt="Logo" className="h-8 sm:h-10" />
          </Link>
        </div>
        <div className="flex flex-col items-center space-y-8 mt-10">
          <Link to="/" onClick={closeNavbar} className="group flex flex-col items-center text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out">
            <i className="zmdi zmdi-home text-2xl"></i>
            <span className="mt-1 text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">Home</span>
          </Link>
          <Link to="/about-us" onClick={closeNavbar} className="group flex flex-col items-center text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out">
            <i className="zmdi zmdi-info text-2xl"></i>
            <span className="mt-1 text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">About</span>
          </Link>
          <Link to="/contact" onClick={closeNavbar} className="group flex flex-col items-center text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out">
            <i className="zmdi zmdi-phone text-2xl"></i>
            <span className="mt-1 text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">Contact</span>
          </Link>
          <Link to="/privacy-policy" onClick={closeNavbar} className="group flex flex-col items-center text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out">
            <i className="zmdi zmdi-shield-security text-2xl"></i>
            <span className="mt-1 text-xs opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">Privacy</span>
          </Link>
        </div>
      </nav>

      {/* Horizontal navbar for small screens */}
      <nav className="md:hidden fixed top-0 w-full z-10 bg-yellow-100 shadow">
        <div className=" mx-auto px-5">
          <div className="flex justify-between h-16 items-center">
            <div className="flex-shrink-0">
              <Link to="/">
                <img src='image/hocal-text-logo.svg' alt="hocal logo, hocal text logo, hocal app text logo with icon, hocal icon , hocalapp logo" className="h-12 sm:h-10" loading='lazy' />
              </Link>
            </div>
            <div>
              <button
                onClick={toggleNavbar} 
                className="text-black focus:outline-none"
                aria-label={isOpen ? "Close navigation menu" : "Open navigation menu"}
              >
                {isOpen ? (
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        <div className={`${isOpen ? 'flex fixed inset-0  px-[20px] py-[100px] rounded-lg bg-yellow-50 ' : 'hidden'} md:hidden`}>
          <div className="flex flex-col space-y-2 text-xl font-semibold">
            <Link to="/" onClick={closeNavbar} className="text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out text-[20px] py-3">Home</Link>
            <Link to="/about-us" onClick={closeNavbar} className="text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out text-[20px] py-3">About</Link>
            <Link to="/contact" onClick={closeNavbar} className="text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out text-[20px] py-3">Contact</Link>
            <Link to="/privacy-policy" onClick={closeNavbar} className="text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out text-[20px] py-3">Privacy Policy</Link>
            <Link to="/terms-conditions" onClick={closeNavbar} className="text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out text-[20px] py-3">Terms & Condition</Link>
            <Link to="/hocal-chief" onClick={closeNavbar} className="text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out text-[20px] py-3">Become Hocal Chief</Link>
            <Link to="/hocal-create-service-request" onClick={closeNavbar} className="text-black hover:text-yellow-500 transition-colors duration-300 ease-in-out text-[20px] py-3">Create service Request</Link>


            <div className="flex space-x-10 pt-8">
              <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer" aria-label="Hocal Facebook" title="Hocal Facebook">
                <FontAwesomeIcon icon={faFacebook} className="text-black text-2xl hover:text-black" />
              </a>
              <a href="https://x.com/hocalServices/" target="_blank" rel="noopener noreferrer" aria-label="Hocal Twitter" title="Hocal Twitter">
                <FontAwesomeIcon icon={faTwitter} className="text-black text-2xl hover:text-black" />
              </a>
              <a href="https://www.instagram.com/hocal_services/" target="_blank" rel="noopener noreferrer" aria-label="Hocal Instagram" title="Hocal Instagram">
                <FontAwesomeIcon icon={faInstagram} className="text-black text-2xl hover:text-black" />
              </a>
              <a href="https://www.linkedin.com/company/hocalservices/" target="_blank" rel="noopener noreferrer" aria-label="Hocal LinkedIn" title="Hocal LinkedIn">
                <FontAwesomeIcon icon={faLinkedin} className="text-black text-2xl hover:text-black mb-5" />
              </a>
            </div>
          </div>

          <div className='pt-[100px] absolute bottom-0 right-0'>
                <img src='image/hocal-text-logo.svg' alt="hocal logo, hocal text logo, hocal app text logo with icon, hocal icon , hocalapp logo" className="h-20 sm:h-10" loading='lazy' />
              </div>
      
          <button
            onClick={toggleNavbar}
            className="absolute top-0 right-0 m-5 text-black focus:outline-none"
            aria-label="Close navigation menu"
          >
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>


        </div>
  
      </nav>

      {/* Add padding top to the content to prevent it from being hidden under the navbar */}
      <div className="pt-8 md:pt-0 bg-white md:ml-16"> {/* Added pt-16 (padding-top: 4rem) for small screens and ml-16 (margin-left: 4rem) for larger screens */}
        {/* Your main content goes here */}
      </div>
    </>
  );
};

export default Navbar;

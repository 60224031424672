import React, { useState } from 'react'

const HowToBecomeHocalChief = () => {
    const sections = [
        {
          heading: 'Enter your service details',
          subtext: 'Details such as Business Name, Offered services, Business Description & Home Delivery availability ?',
          imageUrl: 'image/create-service-request.webp'
        },
        {
          heading: 'Identity verification with aadhaar card',
          subtext: 'Aadhaar verification is must to become a service provider, this is for the safety of other guest',
          imageUrl: 'https://via.placeholder.com/600x400/7f7fff/333333?text=Image+2'
        },
        {
          heading: 'Get verified easily',
          subtext: 'Verification process completes within 24 hours, user will be notified twice if verified or rejected',
          imageUrl: 'https://via.placeholder.com/600x400/7fff7f/333333?text=Image+3'
        },
        {
          heading: 'Start providing service in your area',
          subtext: 'Once verification is completed user can provide service easily with no hassle of time',
          imageUrl: 'https://via.placeholder.com/600x400/ffff7f/333333?text=Image+4'
        },
        
      ];
    
      const [currentImage, setCurrentImage] = useState(sections[0].imageUrl);
    
      const handleClick = (imageUrl) => {
        setCurrentImage(imageUrl);
      };
    
      return (
        <div className=" md:h-screen flex flex-col justify-center md:pl-[100px] py-2 rounded-2xl">
          <h1 className="text-3xl font-bold mb-4 md:p-10 px-5 ">How to become  <span className='bg-yellow-500 text-white rounded-lg px-[10px]'>hocal chief ?</span></h1>
          <div className="flex flex-col md:flex-row w-full">
       {/* Right Side - Image Container */}
       <div className="w-full md:w-1/2 flex justify-center items-center p-5">
              <img src={currentImage} alt="Selected" className="w-full h-auto rounded-lg" />
            </div>
            {/* Left Side - Headings and Subtexts */}
            <div className="w-full md:w-1/2 px-3 md:px-[100px] md:py-5 ">
              {sections.map((section, index) => (
                <div 
                  key={index} 
                  className="mb-4 p-4 border rounded cursor-pointer hover:bg-gray-100" 
                  onClick={() => handleClick(section.imageUrl)}
                >
                  <h3 className="text-xl font-bold mb-2">{section.heading}</h3>
                  <p className="text-gray-600">{section.subtext}</p>
                </div>
              ))}
            </div>
         
          </div>
        </div>
      );
}

export default HowToBecomeHocalChief
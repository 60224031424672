import React from 'react';
import ImageSlider from '../../Swiper/Swiper';

const CreateServiceRequest = () => {
  // Sample data for headings and images
  const sections = [
    {
      step: 1,
      heading: 'Confirm Service Location',
    },
    {
      step: 2,
      heading: 'Create Service Request',
    },
    {
      step: 3,
      heading: 'Select between best time & Price',
    },
    {
      step: 4,
      heading: 'Connect with Hocal Chief & Get Your Work Done',
    },
  ];

  const imagesArray = [
    'image/create-service-request-hocal.svg',
    'image/create-service-request-hocal-2.svg',
    'image/create-service-request-hocal-3.svg',
    'image/create-service-request-hocal-4.svg',
  
  ];

  

  return (
    <div className="flex flex-col justify-center md:px-[100px] md:mt-0 my-5 p-5 md:h-screen rounded-2xl">
      <h1 className="text-3xl font-bold text-left mb-5">
        Easily Create <span className=""></span> Service Request as <span className="text-white bg-yellow-500 rounded px-[5px]">Hocal Guest</span>
      </h1>
      <div className="flex flex-col md:flex-row w-full space-y-8 md:space-y-0 md:space-x-8">
        {/* Right Side - Image Container */}
        <div className="w-full md:w-1/2 flex justify-center items-center p-5 overflow-hidden">
          <ImageSlider images={imagesArray} />
        </div>
        {/* Left Side - Headings and Subtexts */}
        <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
          {sections.map((section, index) => (
            <div key={index} className="mb-8 md:p-6 w-full transition">
              <div className="flex items-center mb-2">
                <span className="bg-yellow-500 text-white font-bold py-1 px-3 rounded-full mr-3">
                  {section.step}
                </span>
                <h3 className="md:text-xl font-bold">{section.heading}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CreateServiceRequest;

import React from 'react';
import { Helmet } from 'react-helmet';
import ImageSlider from '../../Swiper/Swiper';

const EarnMoney = () => {
  const sections = [
    {
      step: 1,
      heading: 'Accept Service Request',
    },
    {
      step: 2,
      heading: 'Enter service & availability charges',
    },
    {
      step: 3,
      heading: 'Contact guest and complete service',
    },
    {
      step: 4,
      heading: 'Enter OTP & ask for feedback',
    },
  ];

  const imagesArray = [
    'image/create-service-request-hocal.svg',
    'image/create-service-request-hocal-2.svg',
    'image/create-service-request-hocal-3.svg',
    'image/create-service-request-hocal-4.svg',
  ];

  return (
    <div className="flex flex-col justify-center md:px-[100px] p-5 md:h-screen md:mt-0 my-5 rounded-2xl">
      <Helmet>
        <title>Earn Money as a Service Provider | Hocal</title>
        <meta name="description" content="Join Hocal and start earning money by providing local services. Accept service requests, set your charges, and help your community with Hocal." />
        <meta name="keywords" content="Hocal, earn money, service provider, local services, accept service request, community help, service charges, local expertise" />
        <meta name="author" content="Hocal Team" />
        <meta property="og:title" content="Earn Money as a Service Provider | Hocal" />
        <meta property="og:description" content="Join Hocal and start earning money by providing local services. Accept service requests, set your charges, and help your community with Hocal." />
        <meta property="og:image" content="URL-to-your-og-image.jpg" />
        <meta property="og:url" content="https://hocal.in/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Earn Money as a Service Provider | Hocal" />
        <meta name="twitter:description" content="Join Hocal and start earning money by providing local services. Accept service requests, set your charges, and help your community with Hocal." />
        <meta name="twitter:image" content="URL-to-your-twitter-image.jpg" />
        <link rel="canonical" href="https://hocal.in/earn-money" />
      </Helmet>

      <h1 className="text-3xl font-bold text-left mb-5">
        Easily <span className="">Accept</span> Service Request as <span className="text-white bg-yellow-500 rounded px-[5px]">Hocal Chief</span>
      </h1>
      <div className="flex flex-col md:flex-row w-full space-y-8 md:space-y-0 md:space-x-8">
        {/* Right Side - Image Container */}
        <div className="w-full md:w-1/2 flex justify-center items-center p-5 overflow-hidden">
          <ImageSlider images={imagesArray} />
        </div>
        {/* Left Side - Headings and Subtexts */}
        <div className="w-full md:w-1/2 flex flex-col justify-center items-center">
          {sections.map((section, index) => (
            <div key={index} className="mb-5 md:p-6 w-full">
              <div className="flex items-center mb-2">
                <span className="bg-yellow-500 text-white font-bold py-1 px-3 rounded-full mr-3">
                  {section.step}
                </span>
                <h3 className="md:text-xl font-bold">{section.heading}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default EarnMoney;

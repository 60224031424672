import React from 'react';
import { Helmet } from 'react-helmet';
import HocalJoinUs from '../Hocal/Components/HocalJoinUs';
import EarnMoney from '../Hocal/Components/EarnMoney';
import HowToBecomeHocalChief from '../Hocal/Components/HowToBecomeHocalChief';

const HocalChief = () => {
  return (
    <div>
      

      <HocalJoinUs />
      <HowToBecomeHocalChief />
      <EarnMoney />

      <Helmet>
        <title>Hocal Chief | Become a Trusted Local Service Provider</title>
        <meta name="description" content="Join Hocal as a Chief and connect with local users seeking various services. Leverage your skills, earn income, and make a positive impact in your community." />
        <meta name="keywords" content="Hocal Chief, local service provider, Hocal services, service provider, become a Hocal Chief, local services, earn income, community impact, service requests, Hocal platform, trusted service provider, flexible work, Hocal registration, make money, side hustle, part-time job, gig economy, local help" />
        <meta name="author" content="Hocal Team" />
        <link rel="canonical" href="https://www.hocal.in/hocal-chief" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Hocal Chief | Become a Trusted Local Service Provider" />
        <meta property="og:description" content="Join Hocal as a Chief and connect with local users seeking various services. Leverage your skills, earn income, and make a positive impact in your community." />
        <meta property="og:image" content="https://hocal.in/image/hocal-chief-banner.svg" />
        <meta property="og:url" content="https://www.hocal.in/hocal-chief" />
        <meta property="og:type" content="article" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hocal Chief | Become a Trusted Local Service Provider" />
        <meta name="twitter:description" content="Join Hocal as a Chief and connect with local users seeking various services. Leverage your skills, earn income, and make a positive impact in your community." />
        <meta name="twitter:image" content="https://hocal.in/image/hocal-chief-banner.svg" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebPage",
              "name": "Hocal Chief | Become a Trusted Local Service Provider",
              "description": "Join Hocal as a Chief and connect with local users seeking various services. Leverage your skills, earn income, and make a positive impact in your community.",
              "url": "https://www.hocal.in/hocal-chief",
              "author": {
                "@type": "Organization",
                "name": "Hocal",
                "url": "https://www.hocal.in",
                "logo": "https://hocal.in/image/hocal-chief-banner.svg",
                "contactPoint": {
                  "@type": "ContactPoint",
                  "email": "hocal.hyperlocal@gmail.com",
                  "contactType": "Customer Support"
                },
                "sameAs": [
                  "https://www.facebook.com/hocal",
                  "https://www.twitter.com/hocal",
                  "https://www.linkedin.com/company/hocal"
                ]
              }
            }
          `}
        </script>
      </Helmet>

    </div>
  );
};

export default HocalChief;

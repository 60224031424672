import React from 'react';
import { Helmet } from 'react-helmet';
import ServicesOnHocal from './ServicesOnHocal';
import CreateServiceRequest from './CreateServiceRequest';
import ConnectCommunity from './HocalConnect';
import EarnMoney from './EarnMoney';
import BusinessWithHocal from './BusinessWithHocal';

const HeroComponent = () => {
  return (
    <div>
  

      <div className="md:px-[100px] p-5 flex flex-col md:flex-row ">
        <div className=" flex flex-col md:flex-row justify-between items-center">
          <div className="text-section md:w-1/2 md:text-left mb-20 md:mb-0">
            <h1 className="md:text-5xl text-3xl font-bold">AnyService, <span className='text-white shadow-md bg-yellow-500 px-[5px] rounded-md'>Anytime,</span>Anywhere</h1>
            <p className="mt-4 text-base">
              Easily get services in your local area with Hocal no matter where you are. Get instant help with Hocal.
            </p>
            <div className="flex flex-row items-center space-y-0 md:space-x-4 space-x-3 mt-10 md:mt-20">
              <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-4 px-8 rounded-md shadow-md focus:outline-none">
                Download App
              </button>
              <button className="bg-white hover:bg-gray-200 text-black font-medium py-4 px-8 rounded-md shadow-md focus:outline-none">
                Know More
              </button>
            </div>
          </div>
          <div className="image-section md:w-1/2 md:p-10">
            <img className="rounded-lg w-full" src="image/hocal-hero.svg" alt="hocal app is there for your day to day needs from home delivery to car repair hocal helps you"  loading='lazy'/>
          
          </div>
        </div>
      </div>


      {/* <ServicesOnHocal/> */}
      <CreateServiceRequest/>
      <ConnectCommunity/>
      <EarnMoney/>
      <BusinessWithHocal/>

      <Helmet>
        <title>Hocal - Connect with Local Service Providers Anytime, Anywhere</title>
        <meta name="description" content="Hocal is your one-stop solution for connecting with local service providers for all your needs. Get services instantly in your local area, no matter where you are. Download the app today!" />
        <meta name="keywords" content="Hocal, local services, service providers, instant help, local area services, download app, home services, community services, connect with providers, anytime anywhere services" />
        <meta name="author" content="Hocal Team" />
        <link rel="canonical" href="https://www.hocal.in" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Hocal - Connect with Local Service Providers Anytime, Anywhere" />
        <meta property="og:description" content="Hocal is your one-stop solution for connecting with local service providers for all your needs. Get services instantly in your local area, no matter where you are. Download the app today!" />
        <meta property="og:image" content="https://hocal.in/image/hocal-hero.svg" />
        <meta property="og:url" content="https://www.hocal.in" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hocal - Connect with Local Service Providers Anytime, Anywhere" />
        <meta name="twitter:description" content="Hocal is your one-stop solution for connecting with local service providers for all your needs. Get services instantly in your local area, no matter where you are. Download the app today!" />
        <meta name="twitter:image" content="https://hocal.in/image/hocal-hero.svg" />

        {/* Schema.org Structured Data */}
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "url": "https://www.hocal.in",
              "name": "Hocal",
              "description": "Hocal is your one-stop solution for connecting with local service providers for all your needs. Get services instantly in your local area, no matter where you are. Download the app today!",
              "publisher": {
                "@type": "Organization",
                "name": "Hocal",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://hocal.in/image/hocal-hero.svg"
                }
              },
              "sameAs": [
                "https://www.facebook.com/hocal",
                "https://www.twitter.com/hocal",
                "https://www.linkedin.com/company/hocal"
              ]
            }
          `}
        </script>
      </Helmet>
 
    </div>
  );
}

export default HeroComponent;

import React from 'react';
import { Helmet } from 'react-helmet';
import ConnectCommunity from '../Hocal/Components/HocalConnect';
import EarnMoney from '../Hocal/Components/EarnMoney';
import CreateServiceRequest from '../Hocal/Components/CreateServiceRequest';
import HocalJoinUs from '../Hocal/Components/HocalJoinUs';

const HocalConnect = () => {
  return (
    <div className="mb-20">
      <Helmet>
        <title>Hocal Connect | Promote Your Services as a Hocal Chief</title>
        <meta name="description" content="Learn how to promote yourself as a Hocal Chief and get more service requests using the Hocal Chief Card. Expand your business by sharing your Service Card on social media and other platforms." />
        <meta name="keywords" content="Hocal Connect, Hocal Chief, promote services, Hocal service card, shareable service card, expand business, local service provider, Hocal platform, get more service requests, service promotion, Hocal app, Hocal marketing, Hocal business growth, community services" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Hocal Connect | Promote Your Services as a Hocal Chief" />
        <meta property="og:description" content="Learn how to promote yourself as a Hocal Chief and get more service requests using the Hocal Chief Card. Expand your business by sharing your Service Card on social media and other platforms." />
        <meta property="og:image" content="https://hocal.in/image/HocalConnectWebImage.svg" />
        <meta property="og:url" content="https://www.hocal.in/hocal-connect" />
        <meta property="og:type" content="article" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hocal Connect | Promote Your Services as a Hocal Chief" />
        <meta name="twitter:description" content="Learn how to promote yourself as a Hocal Chief and get more service requests using the Hocal Chief Card. Expand your business by sharing your Service Card on social media and other platforms." />
        <meta name="twitter:image" content="image/HocalConnectWebImage.svg" />
      </Helmet>
      <ConnectCommunity/>
      <CreateServiceRequest/>
      <EarnMoney/>
      <HocalJoinUs/>
    
    </div>
  );
};

export default HocalConnect;

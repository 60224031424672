import React from 'react';
import Navbar from './Components/Navbar';
import HeroComponent from './Components/HeroComponent';
import ServicesOnHocal from './Components/ServicesOnHocal';
import CreateServiceRequest from './Components/CreateServiceRequest';
import EarnMoney from './Components/EarnMoney';
import ConnectCommunity from './Components/HocalConnect';
import BusinessWithHocal from './Components/BusinessWithHocal';


const Homepage = () => {
  return (
    <>
      <Navbar />
      <HeroComponent/>

      
  

      


      
      

     
    </>
  );
};

export default Homepage;

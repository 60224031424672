import React from 'react';
import { Helmet } from 'react-helmet';
import CreateServiceRequest from '../CreateServiceRequest';

const CarMechanic = () => {
  return (
    <div>


            <div className='md:pl-[100px] pl-5 py-5 '>

      <div className="py-10 flex flex-col md:flex-row justify-between items-center w-full">
        <div className="text-section md:w-1/2 md:text-left mb-20 md:mb-0">
          <h1 className="md:text-6xl text-3xl font-bold">Looking for a <span className='text-white bg-yellow-500 rounded-md px-[10px]'>Car Mechanic?</span></h1>
          <p className="mt-4 text-base">
            Hocal connects you with experienced car mechanics in your local area. Whether you need car repairs, regular maintenance, or emergency vehicle services, our platform helps you find trusted professionals to keep your car running smoothly.
          </p>
          <p className="mt-4 text-base">
                            *Get repair service under Rs.499 (Availability Charge)
                        </p>
          <div className="flex flex-row items-center space-y-0 md:space-x-4 space-x-3 mt-10 md:mt-20">
            <button className="bg-yellow-500 hover:bg-yellow-600 text-white font-medium py-4 px-8 rounded-md shadow-md focus:outline-none">
              Download App
            </button>
            <button className="bg-white hover:bg-gray-200 text-black py-4 px-8 rounded-md shadow-md focus:outline-none">
              Know More
            </button>
          </div>
        </div>
        <div className="image-section md:w-1/2">
          <img className="rounded-lg w-full" src="image/hocal-car-mechanic.svg" alt="Repair your car with hocal services" loading='lazy' />
        </div>
      </div>
      
      <div className='mt-10 pr-5'>
        <h2 className="text-2xl font-bold mb-4">Why Choose Hocal for Car Mechanic Services?</h2>
        <p className="mb-4">
          At Hocal, we understand the importance of having a reliable car mechanic you can trust. Our platform offers:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Easy access to local car mechanics</li>
          <li>Quick response times for emergency repairs</li>
          <li>Affordable rates for car maintenance and services</li>
          <li>Verified professionals for peace of mind</li>
        </ul>
        <h2 className="text-2xl font-bold mb-4">Services Offered by Our Car Mechanics</h2>
        <p className="mb-4">
          Our network of car mechanics can assist you with a wide range of services, including:
        </p>
        <ul className="list-disc list-inside mb-4">
          <li>Engine diagnostics and repairs</li>
          <li>Oil changes and fluid top-ups</li>
          <li>Brake inspections and replacements</li>
          <li>Tire rotations and replacements</li>
          <li>Battery checks and replacements</li>
          <li>General car maintenance and tune-ups</li>
        </ul>
        <p className="mb-0">
          No matter what your car needs, Hocal is here to help you find the right mechanic quickly and easily. Download the app today to get started!
        </p>
      </div>
     
    </div>
    <CreateServiceRequest/>

    <Helmet>
        <title>Car Mechanic Services | Car Repair and Maintenance | Hocal</title>
        <meta name="description" content="Find reliable car mechanic services on Hocal. Get car repairs, maintenance, and vehicle servicing from trusted local professionals. Download the app to connect with car mechanics near you." />
        <meta name="keywords" content="car mechanic, car repair, vehicle maintenance, car service, car repairs, vehicle repair, car maintenance, local car mechanic, Hocal car services" />
        <meta name="robots" content="index, follow" />
        
        {/* Open Graph Tags */}
        <meta property="og:title" content="Car Mechanic Services | Car Repair and Maintenance | Hocal" />
        <meta property="og:description" content="Find reliable car mechanic services on Hocal. Get car repairs, maintenance, and vehicle servicing from trusted local professionals. Download the app to connect with car mechanics near you." />
        <meta property="og:image" content="https://hocal.in/image/hocal-car-mechanic.svg" />
        <meta property="og:url" content="https://www.hocal.in/car-mechanic" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Car Mechanic Services | Car Repair and Maintenance | Hocal" />
        <meta name="twitter:description" content="Find reliable car mechanic services on Hocal. Get car repairs, maintenance, and vehicle servicing from trusted local professionals. Download the app to connect with car mechanics near you." />
        <meta name="twitter:image" content="https://hocal.in/image/hocal-car-mechanic.svg" />
      </Helmet>


    </div>

  )
}

export default CarMechanic;

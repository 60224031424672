import React from 'react';
import { Helmet } from 'react-helmet';
import CreateServiceRequest from '../Hocal/Components/CreateServiceRequest';
import ServicesOnHocal from '../Hocal/Components/ServicesOnHocal';

const HocalCreateServiceRequest = () => {
  return (
    <div>
      <Helmet>
        <title>Create Service Request | Hocal</title>
        <meta name="description" content="Learn how to create a service request on Hocal effortlessly. Find out how anyone can easily create a service request on Hocal and get the service they need in no time." />
        <meta name="keywords" content="hocal create request, create request on hocal, create service request, ask for medicine on hocal, how to create service on hocal, create service request on hocal, local services, service request process, hocal app, quick service requests, trusted service providers" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Create Service Request | Hocal" />
        <meta property="og:description" content="Learn how to create a service request on Hocal effortlessly. Find out how anyone can easily create a service request on Hocal and get the service they need in no time." />
        <meta property="og:image" content="https://hocal.in/image/hocal-create-any-request.svg" />
        <meta property="og:url" content="https://www.hocal.in/create-service-request" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Create Service Request | Hocal" />
        <meta name="twitter:description" content="Learn how to create a service request on Hocal effortlessly. Find out how anyone can easily create a service request on Hocal and get the service they need in no time." />
        <meta name="twitter:image" content="https://hocal.in/image/hocal-create-any-request.svg" />
      </Helmet>
      <div className="flex flex-col lg:flex-row md:h-screen lg:px-[100px] p-5 my-5">
        {/* Image section */}
        <div className="w-full lg:w-1/2 h-1/2 lg:h-full md:p-10 p-5 ">
          <img
            src="/image/hocal-create-request.svg"
            alt="Connecting with community"
            className="w-full h-full object-contain"
          />
        </div>
        {/* Text section */}
        <div className="w-full lg:w-1/2 h-1/2 lg:h-full flex items-center justify-center">
          <div className="md:p-10">
            <h1 className="md:text-4xl text-4xl font-bold">Create any <span className=''>service request</span>  <span className='px-2 bg-yellow-500 text-white'>"कुछ भी, कहीं भी, कभी भी"</span></h1>
            <p className="text-lg my-4">
              Hocal is an intuitive platform where you can effortlessly create service requests anytime, anywhere. With Hocal, convenience is at your fingertips - कुछ भी, कहीं भी, कभी भी! Join us today and revolutionize the way you manage your needs.
            </p>

            <div className="flex flex-row items-center space-y-0 md:space-x-4 space-x-3 mt-10 md:mt-20">
              {/* Download App Button */}
              <button className="bg-yellow-500 hover:bg-yellow-600 text-white  py-4 px-8 rounded-md shadow-md focus:outline-none">
                Download App
              </button>
              {/* Know More Button */}
              <button className="bg-white hover:bg-gray-200 text-black  py-4 px-8 rounded-md shadow-md focus:outline-none">
                Know More
              </button>
            </div>
          </div>
        </div>

      </div>

      <ServicesOnHocal />

      <CreateServiceRequest />
    </div>
  );
};

export default HocalCreateServiceRequest;

import React from 'react';
import { Helmet } from 'react-helmet';
import CreateServiceRequest from '../Hocal/Components/CreateServiceRequest';

const HocalGuest = () => {
  return (
    <div>
      <Helmet>
        <title>Hocal Guest | Discover Local Services Easily</title>
        <meta name="description" content="Learn about Hocal Guest, your gateway to finding and requesting local services efficiently and securely. Explore a wide range of services and enjoy a hassle-free experience." />
        <meta name="keywords" content="Hocal Guest, local services, service requests, Hocal services, Hocal platform, find local services, secure service requests, household tasks, repairs, plumbing, electrical work, home delivery, service providers, Hocal app, community services, trusted service providers, local assistance, reliable services, on-demand services" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Hocal Guest | Discover Local Services Easily" />
        <meta property="og:description" content="Learn about Hocal Guest, your gateway to finding and requesting local services efficiently and securely. Explore a wide range of services and enjoy a hassle-free experience." />
        <meta property="og:image" content="https://hocal.in/image/hocal-business-delivery.svg" />
        <meta property="og:url" content="https://www.hocal.in/hocal-guest" />
        <meta property="og:type" content="website" />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Hocal Guest | Discover Local Services Easily" />
        <meta name="twitter:description" content="Learn about Hocal Guest, your gateway to finding and requesting local services efficiently and securely. Explore a wide range of services and enjoy a hassle-free experience." />
        <meta name="twitter:image" content="https://hocal.in/image/hocal-business-delivery.svg" />
      </Helmet>


      <div class=" md:pt-0 md:px-[100px] p-5 my-5 flex flex-col md:flex-row md:h-screen ">

        <div className="py-5 flex flex-col md:flex-row justify-between items-center w-full">
          <div className="text-section md:w-1/2 md:text-left mb-20 md:mb-0">
            <h1 className="md:text-6xl text-3xl font-bold">Save <span className='px-2 bg-yellow-500 rounded-lg text-white'> money & time</span> with hocal</h1>

            <p className="mt-4 text-base">
              Get all your services done with hocal. Whether you need help with household chores, repairs, or professional services, Hocal connects you with trusted local providers to meet your needs efficiently and securely.

            </p>



            <div className="flex flex-row items-center space-y-0 md:space-x-4 space-x-3 mt-10 md:mt-20">
              {/* Download App Button */}
              <button className="bg-yellow-500 hover:bg-yellow-600 text-white py-4 px-8 rounded-md shadow-md focus:outline-none">
                Download App
              </button>
              {/* Know More Button */}
              <button className="bg-white hover:bg-gray-200 text-black py-4 px-8 rounded-md shadow-md focus:outline-none">
                Know More
              </button>
            </div>
          </div>
          <div className="image-section md:w-1/2 md:px-[60px]">
            <img className="rounded-lg w-full" src="image/save-time-money.svg" alt="Get hyperlocal services as home delivery, medicine delivery, food delivery easily with hocal app hocal is free for all where hocal guest can save money and time" loading='lazy' />
          </div>
        </div>



      </div>

      <CreateServiceRequest />


    </div>
  );
};

export default HocalGuest;
